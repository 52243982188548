import _conventions from "./conventions";
var exports = {};
var freeze = _conventions.freeze;

/**
 * The entities that are predefined in every XML document.
 *
 * @see https://www.w3.org/TR/2006/REC-xml11-20060816/#sec-predefined-ent W3C XML 1.1
 * @see https://www.w3.org/TR/2008/REC-xml-20081126/#sec-predefined-ent W3C XML 1.0
 * @see https://en.wikipedia.org/wiki/List_of_XML_and_HTML_character_entity_references#Predefined_entities_in_XML Wikipedia
 */
exports.XML_ENTITIES = freeze({
  amp: "&",
  apos: "'",
  gt: ">",
  lt: "<",
  quot: "\""
});

/**
 * A map of all entities that are detected in an HTML document.
 * They contain all entries from `XML_ENTITIES`.
 *
 * @see XML_ENTITIES
 * @see DOMParser.parseFromString
 * @see DOMImplementation.prototype.createHTMLDocument
 * @see https://html.spec.whatwg.org/#named-character-references WHATWG HTML(5) Spec
 * @see https://html.spec.whatwg.org/entities.json JSON
 * @see https://www.w3.org/TR/xml-entity-names/ W3C XML Entity Names
 * @see https://www.w3.org/TR/html4/sgml/entities.html W3C HTML4/SGML
 * @see https://en.wikipedia.org/wiki/List_of_XML_and_HTML_character_entity_references#Character_entity_references_in_HTML Wikipedia (HTML)
 * @see https://en.wikipedia.org/wiki/List_of_XML_and_HTML_character_entity_references#Entities_representing_special_characters_in_XHTML Wikpedia (XHTML)
 */
exports.HTML_ENTITIES = freeze({
  Aacute: "\xC1",
  aacute: "\xE1",
  Abreve: "\u0102",
  abreve: "\u0103",
  ac: "\u223E",
  acd: "\u223F",
  acE: "\u223E\u0333",
  Acirc: "\xC2",
  acirc: "\xE2",
  acute: "\xB4",
  Acy: "\u0410",
  acy: "\u0430",
  AElig: "\xC6",
  aelig: "\xE6",
  af: "\u2061",
  Afr: "\uD835\uDD04",
  afr: "\uD835\uDD1E",
  Agrave: "\xC0",
  agrave: "\xE0",
  alefsym: "\u2135",
  aleph: "\u2135",
  Alpha: "\u0391",
  alpha: "\u03B1",
  Amacr: "\u0100",
  amacr: "\u0101",
  amalg: "\u2A3F",
  AMP: "&",
  amp: "&",
  And: "\u2A53",
  and: "\u2227",
  andand: "\u2A55",
  andd: "\u2A5C",
  andslope: "\u2A58",
  andv: "\u2A5A",
  ang: "\u2220",
  ange: "\u29A4",
  angle: "\u2220",
  angmsd: "\u2221",
  angmsdaa: "\u29A8",
  angmsdab: "\u29A9",
  angmsdac: "\u29AA",
  angmsdad: "\u29AB",
  angmsdae: "\u29AC",
  angmsdaf: "\u29AD",
  angmsdag: "\u29AE",
  angmsdah: "\u29AF",
  angrt: "\u221F",
  angrtvb: "\u22BE",
  angrtvbd: "\u299D",
  angsph: "\u2222",
  angst: "\xC5",
  angzarr: "\u237C",
  Aogon: "\u0104",
  aogon: "\u0105",
  Aopf: "\uD835\uDD38",
  aopf: "\uD835\uDD52",
  ap: "\u2248",
  apacir: "\u2A6F",
  apE: "\u2A70",
  ape: "\u224A",
  apid: "\u224B",
  apos: "'",
  ApplyFunction: "\u2061",
  approx: "\u2248",
  approxeq: "\u224A",
  Aring: "\xC5",
  aring: "\xE5",
  Ascr: "\uD835\uDC9C",
  ascr: "\uD835\uDCB6",
  Assign: "\u2254",
  ast: "*",
  asymp: "\u2248",
  asympeq: "\u224D",
  Atilde: "\xC3",
  atilde: "\xE3",
  Auml: "\xC4",
  auml: "\xE4",
  awconint: "\u2233",
  awint: "\u2A11",
  backcong: "\u224C",
  backepsilon: "\u03F6",
  backprime: "\u2035",
  backsim: "\u223D",
  backsimeq: "\u22CD",
  Backslash: "\u2216",
  Barv: "\u2AE7",
  barvee: "\u22BD",
  Barwed: "\u2306",
  barwed: "\u2305",
  barwedge: "\u2305",
  bbrk: "\u23B5",
  bbrktbrk: "\u23B6",
  bcong: "\u224C",
  Bcy: "\u0411",
  bcy: "\u0431",
  bdquo: "\u201E",
  becaus: "\u2235",
  Because: "\u2235",
  because: "\u2235",
  bemptyv: "\u29B0",
  bepsi: "\u03F6",
  bernou: "\u212C",
  Bernoullis: "\u212C",
  Beta: "\u0392",
  beta: "\u03B2",
  beth: "\u2136",
  between: "\u226C",
  Bfr: "\uD835\uDD05",
  bfr: "\uD835\uDD1F",
  bigcap: "\u22C2",
  bigcirc: "\u25EF",
  bigcup: "\u22C3",
  bigodot: "\u2A00",
  bigoplus: "\u2A01",
  bigotimes: "\u2A02",
  bigsqcup: "\u2A06",
  bigstar: "\u2605",
  bigtriangledown: "\u25BD",
  bigtriangleup: "\u25B3",
  biguplus: "\u2A04",
  bigvee: "\u22C1",
  bigwedge: "\u22C0",
  bkarow: "\u290D",
  blacklozenge: "\u29EB",
  blacksquare: "\u25AA",
  blacktriangle: "\u25B4",
  blacktriangledown: "\u25BE",
  blacktriangleleft: "\u25C2",
  blacktriangleright: "\u25B8",
  blank: "\u2423",
  blk12: "\u2592",
  blk14: "\u2591",
  blk34: "\u2593",
  block: "\u2588",
  bne: "=\u20E5",
  bnequiv: "\u2261\u20E5",
  bNot: "\u2AED",
  bnot: "\u2310",
  Bopf: "\uD835\uDD39",
  bopf: "\uD835\uDD53",
  bot: "\u22A5",
  bottom: "\u22A5",
  bowtie: "\u22C8",
  boxbox: "\u29C9",
  boxDL: "\u2557",
  boxDl: "\u2556",
  boxdL: "\u2555",
  boxdl: "\u2510",
  boxDR: "\u2554",
  boxDr: "\u2553",
  boxdR: "\u2552",
  boxdr: "\u250C",
  boxH: "\u2550",
  boxh: "\u2500",
  boxHD: "\u2566",
  boxHd: "\u2564",
  boxhD: "\u2565",
  boxhd: "\u252C",
  boxHU: "\u2569",
  boxHu: "\u2567",
  boxhU: "\u2568",
  boxhu: "\u2534",
  boxminus: "\u229F",
  boxplus: "\u229E",
  boxtimes: "\u22A0",
  boxUL: "\u255D",
  boxUl: "\u255C",
  boxuL: "\u255B",
  boxul: "\u2518",
  boxUR: "\u255A",
  boxUr: "\u2559",
  boxuR: "\u2558",
  boxur: "\u2514",
  boxV: "\u2551",
  boxv: "\u2502",
  boxVH: "\u256C",
  boxVh: "\u256B",
  boxvH: "\u256A",
  boxvh: "\u253C",
  boxVL: "\u2563",
  boxVl: "\u2562",
  boxvL: "\u2561",
  boxvl: "\u2524",
  boxVR: "\u2560",
  boxVr: "\u255F",
  boxvR: "\u255E",
  boxvr: "\u251C",
  bprime: "\u2035",
  Breve: "\u02D8",
  breve: "\u02D8",
  brvbar: "\xA6",
  Bscr: "\u212C",
  bscr: "\uD835\uDCB7",
  bsemi: "\u204F",
  bsim: "\u223D",
  bsime: "\u22CD",
  bsol: "\\",
  bsolb: "\u29C5",
  bsolhsub: "\u27C8",
  bull: "\u2022",
  bullet: "\u2022",
  bump: "\u224E",
  bumpE: "\u2AAE",
  bumpe: "\u224F",
  Bumpeq: "\u224E",
  bumpeq: "\u224F",
  Cacute: "\u0106",
  cacute: "\u0107",
  Cap: "\u22D2",
  cap: "\u2229",
  capand: "\u2A44",
  capbrcup: "\u2A49",
  capcap: "\u2A4B",
  capcup: "\u2A47",
  capdot: "\u2A40",
  CapitalDifferentialD: "\u2145",
  caps: "\u2229\uFE00",
  caret: "\u2041",
  caron: "\u02C7",
  Cayleys: "\u212D",
  ccaps: "\u2A4D",
  Ccaron: "\u010C",
  ccaron: "\u010D",
  Ccedil: "\xC7",
  ccedil: "\xE7",
  Ccirc: "\u0108",
  ccirc: "\u0109",
  Cconint: "\u2230",
  ccups: "\u2A4C",
  ccupssm: "\u2A50",
  Cdot: "\u010A",
  cdot: "\u010B",
  cedil: "\xB8",
  Cedilla: "\xB8",
  cemptyv: "\u29B2",
  cent: "\xA2",
  CenterDot: "\xB7",
  centerdot: "\xB7",
  Cfr: "\u212D",
  cfr: "\uD835\uDD20",
  CHcy: "\u0427",
  chcy: "\u0447",
  check: "\u2713",
  checkmark: "\u2713",
  Chi: "\u03A7",
  chi: "\u03C7",
  cir: "\u25CB",
  circ: "\u02C6",
  circeq: "\u2257",
  circlearrowleft: "\u21BA",
  circlearrowright: "\u21BB",
  circledast: "\u229B",
  circledcirc: "\u229A",
  circleddash: "\u229D",
  CircleDot: "\u2299",
  circledR: "\xAE",
  circledS: "\u24C8",
  CircleMinus: "\u2296",
  CirclePlus: "\u2295",
  CircleTimes: "\u2297",
  cirE: "\u29C3",
  cire: "\u2257",
  cirfnint: "\u2A10",
  cirmid: "\u2AEF",
  cirscir: "\u29C2",
  ClockwiseContourIntegral: "\u2232",
  CloseCurlyDoubleQuote: "\u201D",
  CloseCurlyQuote: "\u2019",
  clubs: "\u2663",
  clubsuit: "\u2663",
  Colon: "\u2237",
  colon: ":",
  Colone: "\u2A74",
  colone: "\u2254",
  coloneq: "\u2254",
  comma: ",",
  commat: "@",
  comp: "\u2201",
  compfn: "\u2218",
  complement: "\u2201",
  complexes: "\u2102",
  cong: "\u2245",
  congdot: "\u2A6D",
  Congruent: "\u2261",
  Conint: "\u222F",
  conint: "\u222E",
  ContourIntegral: "\u222E",
  Copf: "\u2102",
  copf: "\uD835\uDD54",
  coprod: "\u2210",
  Coproduct: "\u2210",
  COPY: "\xA9",
  copy: "\xA9",
  copysr: "\u2117",
  CounterClockwiseContourIntegral: "\u2233",
  crarr: "\u21B5",
  Cross: "\u2A2F",
  cross: "\u2717",
  Cscr: "\uD835\uDC9E",
  cscr: "\uD835\uDCB8",
  csub: "\u2ACF",
  csube: "\u2AD1",
  csup: "\u2AD0",
  csupe: "\u2AD2",
  ctdot: "\u22EF",
  cudarrl: "\u2938",
  cudarrr: "\u2935",
  cuepr: "\u22DE",
  cuesc: "\u22DF",
  cularr: "\u21B6",
  cularrp: "\u293D",
  Cup: "\u22D3",
  cup: "\u222A",
  cupbrcap: "\u2A48",
  CupCap: "\u224D",
  cupcap: "\u2A46",
  cupcup: "\u2A4A",
  cupdot: "\u228D",
  cupor: "\u2A45",
  cups: "\u222A\uFE00",
  curarr: "\u21B7",
  curarrm: "\u293C",
  curlyeqprec: "\u22DE",
  curlyeqsucc: "\u22DF",
  curlyvee: "\u22CE",
  curlywedge: "\u22CF",
  curren: "\xA4",
  curvearrowleft: "\u21B6",
  curvearrowright: "\u21B7",
  cuvee: "\u22CE",
  cuwed: "\u22CF",
  cwconint: "\u2232",
  cwint: "\u2231",
  cylcty: "\u232D",
  Dagger: "\u2021",
  dagger: "\u2020",
  daleth: "\u2138",
  Darr: "\u21A1",
  dArr: "\u21D3",
  darr: "\u2193",
  dash: "\u2010",
  Dashv: "\u2AE4",
  dashv: "\u22A3",
  dbkarow: "\u290F",
  dblac: "\u02DD",
  Dcaron: "\u010E",
  dcaron: "\u010F",
  Dcy: "\u0414",
  dcy: "\u0434",
  DD: "\u2145",
  dd: "\u2146",
  ddagger: "\u2021",
  ddarr: "\u21CA",
  DDotrahd: "\u2911",
  ddotseq: "\u2A77",
  deg: "\xB0",
  Del: "\u2207",
  Delta: "\u0394",
  delta: "\u03B4",
  demptyv: "\u29B1",
  dfisht: "\u297F",
  Dfr: "\uD835\uDD07",
  dfr: "\uD835\uDD21",
  dHar: "\u2965",
  dharl: "\u21C3",
  dharr: "\u21C2",
  DiacriticalAcute: "\xB4",
  DiacriticalDot: "\u02D9",
  DiacriticalDoubleAcute: "\u02DD",
  DiacriticalGrave: "`",
  DiacriticalTilde: "\u02DC",
  diam: "\u22C4",
  Diamond: "\u22C4",
  diamond: "\u22C4",
  diamondsuit: "\u2666",
  diams: "\u2666",
  die: "\xA8",
  DifferentialD: "\u2146",
  digamma: "\u03DD",
  disin: "\u22F2",
  div: "\xF7",
  divide: "\xF7",
  divideontimes: "\u22C7",
  divonx: "\u22C7",
  DJcy: "\u0402",
  djcy: "\u0452",
  dlcorn: "\u231E",
  dlcrop: "\u230D",
  dollar: "$",
  Dopf: "\uD835\uDD3B",
  dopf: "\uD835\uDD55",
  Dot: "\xA8",
  dot: "\u02D9",
  DotDot: "\u20DC",
  doteq: "\u2250",
  doteqdot: "\u2251",
  DotEqual: "\u2250",
  dotminus: "\u2238",
  dotplus: "\u2214",
  dotsquare: "\u22A1",
  doublebarwedge: "\u2306",
  DoubleContourIntegral: "\u222F",
  DoubleDot: "\xA8",
  DoubleDownArrow: "\u21D3",
  DoubleLeftArrow: "\u21D0",
  DoubleLeftRightArrow: "\u21D4",
  DoubleLeftTee: "\u2AE4",
  DoubleLongLeftArrow: "\u27F8",
  DoubleLongLeftRightArrow: "\u27FA",
  DoubleLongRightArrow: "\u27F9",
  DoubleRightArrow: "\u21D2",
  DoubleRightTee: "\u22A8",
  DoubleUpArrow: "\u21D1",
  DoubleUpDownArrow: "\u21D5",
  DoubleVerticalBar: "\u2225",
  DownArrow: "\u2193",
  Downarrow: "\u21D3",
  downarrow: "\u2193",
  DownArrowBar: "\u2913",
  DownArrowUpArrow: "\u21F5",
  DownBreve: "\u0311",
  downdownarrows: "\u21CA",
  downharpoonleft: "\u21C3",
  downharpoonright: "\u21C2",
  DownLeftRightVector: "\u2950",
  DownLeftTeeVector: "\u295E",
  DownLeftVector: "\u21BD",
  DownLeftVectorBar: "\u2956",
  DownRightTeeVector: "\u295F",
  DownRightVector: "\u21C1",
  DownRightVectorBar: "\u2957",
  DownTee: "\u22A4",
  DownTeeArrow: "\u21A7",
  drbkarow: "\u2910",
  drcorn: "\u231F",
  drcrop: "\u230C",
  Dscr: "\uD835\uDC9F",
  dscr: "\uD835\uDCB9",
  DScy: "\u0405",
  dscy: "\u0455",
  dsol: "\u29F6",
  Dstrok: "\u0110",
  dstrok: "\u0111",
  dtdot: "\u22F1",
  dtri: "\u25BF",
  dtrif: "\u25BE",
  duarr: "\u21F5",
  duhar: "\u296F",
  dwangle: "\u29A6",
  DZcy: "\u040F",
  dzcy: "\u045F",
  dzigrarr: "\u27FF",
  Eacute: "\xC9",
  eacute: "\xE9",
  easter: "\u2A6E",
  Ecaron: "\u011A",
  ecaron: "\u011B",
  ecir: "\u2256",
  Ecirc: "\xCA",
  ecirc: "\xEA",
  ecolon: "\u2255",
  Ecy: "\u042D",
  ecy: "\u044D",
  eDDot: "\u2A77",
  Edot: "\u0116",
  eDot: "\u2251",
  edot: "\u0117",
  ee: "\u2147",
  efDot: "\u2252",
  Efr: "\uD835\uDD08",
  efr: "\uD835\uDD22",
  eg: "\u2A9A",
  Egrave: "\xC8",
  egrave: "\xE8",
  egs: "\u2A96",
  egsdot: "\u2A98",
  el: "\u2A99",
  Element: "\u2208",
  elinters: "\u23E7",
  ell: "\u2113",
  els: "\u2A95",
  elsdot: "\u2A97",
  Emacr: "\u0112",
  emacr: "\u0113",
  empty: "\u2205",
  emptyset: "\u2205",
  EmptySmallSquare: "\u25FB",
  emptyv: "\u2205",
  EmptyVerySmallSquare: "\u25AB",
  emsp: "\u2003",
  emsp13: "\u2004",
  emsp14: "\u2005",
  ENG: "\u014A",
  eng: "\u014B",
  ensp: "\u2002",
  Eogon: "\u0118",
  eogon: "\u0119",
  Eopf: "\uD835\uDD3C",
  eopf: "\uD835\uDD56",
  epar: "\u22D5",
  eparsl: "\u29E3",
  eplus: "\u2A71",
  epsi: "\u03B5",
  Epsilon: "\u0395",
  epsilon: "\u03B5",
  epsiv: "\u03F5",
  eqcirc: "\u2256",
  eqcolon: "\u2255",
  eqsim: "\u2242",
  eqslantgtr: "\u2A96",
  eqslantless: "\u2A95",
  Equal: "\u2A75",
  equals: "=",
  EqualTilde: "\u2242",
  equest: "\u225F",
  Equilibrium: "\u21CC",
  equiv: "\u2261",
  equivDD: "\u2A78",
  eqvparsl: "\u29E5",
  erarr: "\u2971",
  erDot: "\u2253",
  Escr: "\u2130",
  escr: "\u212F",
  esdot: "\u2250",
  Esim: "\u2A73",
  esim: "\u2242",
  Eta: "\u0397",
  eta: "\u03B7",
  ETH: "\xD0",
  eth: "\xF0",
  Euml: "\xCB",
  euml: "\xEB",
  euro: "\u20AC",
  excl: "!",
  exist: "\u2203",
  Exists: "\u2203",
  expectation: "\u2130",
  ExponentialE: "\u2147",
  exponentiale: "\u2147",
  fallingdotseq: "\u2252",
  Fcy: "\u0424",
  fcy: "\u0444",
  female: "\u2640",
  ffilig: "\uFB03",
  fflig: "\uFB00",
  ffllig: "\uFB04",
  Ffr: "\uD835\uDD09",
  ffr: "\uD835\uDD23",
  filig: "\uFB01",
  FilledSmallSquare: "\u25FC",
  FilledVerySmallSquare: "\u25AA",
  fjlig: "fj",
  flat: "\u266D",
  fllig: "\uFB02",
  fltns: "\u25B1",
  fnof: "\u0192",
  Fopf: "\uD835\uDD3D",
  fopf: "\uD835\uDD57",
  ForAll: "\u2200",
  forall: "\u2200",
  fork: "\u22D4",
  forkv: "\u2AD9",
  Fouriertrf: "\u2131",
  fpartint: "\u2A0D",
  frac12: "\xBD",
  frac13: "\u2153",
  frac14: "\xBC",
  frac15: "\u2155",
  frac16: "\u2159",
  frac18: "\u215B",
  frac23: "\u2154",
  frac25: "\u2156",
  frac34: "\xBE",
  frac35: "\u2157",
  frac38: "\u215C",
  frac45: "\u2158",
  frac56: "\u215A",
  frac58: "\u215D",
  frac78: "\u215E",
  frasl: "\u2044",
  frown: "\u2322",
  Fscr: "\u2131",
  fscr: "\uD835\uDCBB",
  gacute: "\u01F5",
  Gamma: "\u0393",
  gamma: "\u03B3",
  Gammad: "\u03DC",
  gammad: "\u03DD",
  gap: "\u2A86",
  Gbreve: "\u011E",
  gbreve: "\u011F",
  Gcedil: "\u0122",
  Gcirc: "\u011C",
  gcirc: "\u011D",
  Gcy: "\u0413",
  gcy: "\u0433",
  Gdot: "\u0120",
  gdot: "\u0121",
  gE: "\u2267",
  ge: "\u2265",
  gEl: "\u2A8C",
  gel: "\u22DB",
  geq: "\u2265",
  geqq: "\u2267",
  geqslant: "\u2A7E",
  ges: "\u2A7E",
  gescc: "\u2AA9",
  gesdot: "\u2A80",
  gesdoto: "\u2A82",
  gesdotol: "\u2A84",
  gesl: "\u22DB\uFE00",
  gesles: "\u2A94",
  Gfr: "\uD835\uDD0A",
  gfr: "\uD835\uDD24",
  Gg: "\u22D9",
  gg: "\u226B",
  ggg: "\u22D9",
  gimel: "\u2137",
  GJcy: "\u0403",
  gjcy: "\u0453",
  gl: "\u2277",
  gla: "\u2AA5",
  glE: "\u2A92",
  glj: "\u2AA4",
  gnap: "\u2A8A",
  gnapprox: "\u2A8A",
  gnE: "\u2269",
  gne: "\u2A88",
  gneq: "\u2A88",
  gneqq: "\u2269",
  gnsim: "\u22E7",
  Gopf: "\uD835\uDD3E",
  gopf: "\uD835\uDD58",
  grave: "`",
  GreaterEqual: "\u2265",
  GreaterEqualLess: "\u22DB",
  GreaterFullEqual: "\u2267",
  GreaterGreater: "\u2AA2",
  GreaterLess: "\u2277",
  GreaterSlantEqual: "\u2A7E",
  GreaterTilde: "\u2273",
  Gscr: "\uD835\uDCA2",
  gscr: "\u210A",
  gsim: "\u2273",
  gsime: "\u2A8E",
  gsiml: "\u2A90",
  Gt: "\u226B",
  GT: ">",
  gt: ">",
  gtcc: "\u2AA7",
  gtcir: "\u2A7A",
  gtdot: "\u22D7",
  gtlPar: "\u2995",
  gtquest: "\u2A7C",
  gtrapprox: "\u2A86",
  gtrarr: "\u2978",
  gtrdot: "\u22D7",
  gtreqless: "\u22DB",
  gtreqqless: "\u2A8C",
  gtrless: "\u2277",
  gtrsim: "\u2273",
  gvertneqq: "\u2269\uFE00",
  gvnE: "\u2269\uFE00",
  Hacek: "\u02C7",
  hairsp: "\u200A",
  half: "\xBD",
  hamilt: "\u210B",
  HARDcy: "\u042A",
  hardcy: "\u044A",
  hArr: "\u21D4",
  harr: "\u2194",
  harrcir: "\u2948",
  harrw: "\u21AD",
  Hat: "^",
  hbar: "\u210F",
  Hcirc: "\u0124",
  hcirc: "\u0125",
  hearts: "\u2665",
  heartsuit: "\u2665",
  hellip: "\u2026",
  hercon: "\u22B9",
  Hfr: "\u210C",
  hfr: "\uD835\uDD25",
  HilbertSpace: "\u210B",
  hksearow: "\u2925",
  hkswarow: "\u2926",
  hoarr: "\u21FF",
  homtht: "\u223B",
  hookleftarrow: "\u21A9",
  hookrightarrow: "\u21AA",
  Hopf: "\u210D",
  hopf: "\uD835\uDD59",
  horbar: "\u2015",
  HorizontalLine: "\u2500",
  Hscr: "\u210B",
  hscr: "\uD835\uDCBD",
  hslash: "\u210F",
  Hstrok: "\u0126",
  hstrok: "\u0127",
  HumpDownHump: "\u224E",
  HumpEqual: "\u224F",
  hybull: "\u2043",
  hyphen: "\u2010",
  Iacute: "\xCD",
  iacute: "\xED",
  ic: "\u2063",
  Icirc: "\xCE",
  icirc: "\xEE",
  Icy: "\u0418",
  icy: "\u0438",
  Idot: "\u0130",
  IEcy: "\u0415",
  iecy: "\u0435",
  iexcl: "\xA1",
  iff: "\u21D4",
  Ifr: "\u2111",
  ifr: "\uD835\uDD26",
  Igrave: "\xCC",
  igrave: "\xEC",
  ii: "\u2148",
  iiiint: "\u2A0C",
  iiint: "\u222D",
  iinfin: "\u29DC",
  iiota: "\u2129",
  IJlig: "\u0132",
  ijlig: "\u0133",
  Im: "\u2111",
  Imacr: "\u012A",
  imacr: "\u012B",
  image: "\u2111",
  ImaginaryI: "\u2148",
  imagline: "\u2110",
  imagpart: "\u2111",
  imath: "\u0131",
  imof: "\u22B7",
  imped: "\u01B5",
  Implies: "\u21D2",
  in: "\u2208",
  incare: "\u2105",
  infin: "\u221E",
  infintie: "\u29DD",
  inodot: "\u0131",
  Int: "\u222C",
  int: "\u222B",
  intcal: "\u22BA",
  integers: "\u2124",
  Integral: "\u222B",
  intercal: "\u22BA",
  Intersection: "\u22C2",
  intlarhk: "\u2A17",
  intprod: "\u2A3C",
  InvisibleComma: "\u2063",
  InvisibleTimes: "\u2062",
  IOcy: "\u0401",
  iocy: "\u0451",
  Iogon: "\u012E",
  iogon: "\u012F",
  Iopf: "\uD835\uDD40",
  iopf: "\uD835\uDD5A",
  Iota: "\u0399",
  iota: "\u03B9",
  iprod: "\u2A3C",
  iquest: "\xBF",
  Iscr: "\u2110",
  iscr: "\uD835\uDCBE",
  isin: "\u2208",
  isindot: "\u22F5",
  isinE: "\u22F9",
  isins: "\u22F4",
  isinsv: "\u22F3",
  isinv: "\u2208",
  it: "\u2062",
  Itilde: "\u0128",
  itilde: "\u0129",
  Iukcy: "\u0406",
  iukcy: "\u0456",
  Iuml: "\xCF",
  iuml: "\xEF",
  Jcirc: "\u0134",
  jcirc: "\u0135",
  Jcy: "\u0419",
  jcy: "\u0439",
  Jfr: "\uD835\uDD0D",
  jfr: "\uD835\uDD27",
  jmath: "\u0237",
  Jopf: "\uD835\uDD41",
  jopf: "\uD835\uDD5B",
  Jscr: "\uD835\uDCA5",
  jscr: "\uD835\uDCBF",
  Jsercy: "\u0408",
  jsercy: "\u0458",
  Jukcy: "\u0404",
  jukcy: "\u0454",
  Kappa: "\u039A",
  kappa: "\u03BA",
  kappav: "\u03F0",
  Kcedil: "\u0136",
  kcedil: "\u0137",
  Kcy: "\u041A",
  kcy: "\u043A",
  Kfr: "\uD835\uDD0E",
  kfr: "\uD835\uDD28",
  kgreen: "\u0138",
  KHcy: "\u0425",
  khcy: "\u0445",
  KJcy: "\u040C",
  kjcy: "\u045C",
  Kopf: "\uD835\uDD42",
  kopf: "\uD835\uDD5C",
  Kscr: "\uD835\uDCA6",
  kscr: "\uD835\uDCC0",
  lAarr: "\u21DA",
  Lacute: "\u0139",
  lacute: "\u013A",
  laemptyv: "\u29B4",
  lagran: "\u2112",
  Lambda: "\u039B",
  lambda: "\u03BB",
  Lang: "\u27EA",
  lang: "\u27E8",
  langd: "\u2991",
  langle: "\u27E8",
  lap: "\u2A85",
  Laplacetrf: "\u2112",
  laquo: "\xAB",
  Larr: "\u219E",
  lArr: "\u21D0",
  larr: "\u2190",
  larrb: "\u21E4",
  larrbfs: "\u291F",
  larrfs: "\u291D",
  larrhk: "\u21A9",
  larrlp: "\u21AB",
  larrpl: "\u2939",
  larrsim: "\u2973",
  larrtl: "\u21A2",
  lat: "\u2AAB",
  lAtail: "\u291B",
  latail: "\u2919",
  late: "\u2AAD",
  lates: "\u2AAD\uFE00",
  lBarr: "\u290E",
  lbarr: "\u290C",
  lbbrk: "\u2772",
  lbrace: "{",
  lbrack: "[",
  lbrke: "\u298B",
  lbrksld: "\u298F",
  lbrkslu: "\u298D",
  Lcaron: "\u013D",
  lcaron: "\u013E",
  Lcedil: "\u013B",
  lcedil: "\u013C",
  lceil: "\u2308",
  lcub: "{",
  Lcy: "\u041B",
  lcy: "\u043B",
  ldca: "\u2936",
  ldquo: "\u201C",
  ldquor: "\u201E",
  ldrdhar: "\u2967",
  ldrushar: "\u294B",
  ldsh: "\u21B2",
  lE: "\u2266",
  le: "\u2264",
  LeftAngleBracket: "\u27E8",
  LeftArrow: "\u2190",
  Leftarrow: "\u21D0",
  leftarrow: "\u2190",
  LeftArrowBar: "\u21E4",
  LeftArrowRightArrow: "\u21C6",
  leftarrowtail: "\u21A2",
  LeftCeiling: "\u2308",
  LeftDoubleBracket: "\u27E6",
  LeftDownTeeVector: "\u2961",
  LeftDownVector: "\u21C3",
  LeftDownVectorBar: "\u2959",
  LeftFloor: "\u230A",
  leftharpoondown: "\u21BD",
  leftharpoonup: "\u21BC",
  leftleftarrows: "\u21C7",
  LeftRightArrow: "\u2194",
  Leftrightarrow: "\u21D4",
  leftrightarrow: "\u2194",
  leftrightarrows: "\u21C6",
  leftrightharpoons: "\u21CB",
  leftrightsquigarrow: "\u21AD",
  LeftRightVector: "\u294E",
  LeftTee: "\u22A3",
  LeftTeeArrow: "\u21A4",
  LeftTeeVector: "\u295A",
  leftthreetimes: "\u22CB",
  LeftTriangle: "\u22B2",
  LeftTriangleBar: "\u29CF",
  LeftTriangleEqual: "\u22B4",
  LeftUpDownVector: "\u2951",
  LeftUpTeeVector: "\u2960",
  LeftUpVector: "\u21BF",
  LeftUpVectorBar: "\u2958",
  LeftVector: "\u21BC",
  LeftVectorBar: "\u2952",
  lEg: "\u2A8B",
  leg: "\u22DA",
  leq: "\u2264",
  leqq: "\u2266",
  leqslant: "\u2A7D",
  les: "\u2A7D",
  lescc: "\u2AA8",
  lesdot: "\u2A7F",
  lesdoto: "\u2A81",
  lesdotor: "\u2A83",
  lesg: "\u22DA\uFE00",
  lesges: "\u2A93",
  lessapprox: "\u2A85",
  lessdot: "\u22D6",
  lesseqgtr: "\u22DA",
  lesseqqgtr: "\u2A8B",
  LessEqualGreater: "\u22DA",
  LessFullEqual: "\u2266",
  LessGreater: "\u2276",
  lessgtr: "\u2276",
  LessLess: "\u2AA1",
  lesssim: "\u2272",
  LessSlantEqual: "\u2A7D",
  LessTilde: "\u2272",
  lfisht: "\u297C",
  lfloor: "\u230A",
  Lfr: "\uD835\uDD0F",
  lfr: "\uD835\uDD29",
  lg: "\u2276",
  lgE: "\u2A91",
  lHar: "\u2962",
  lhard: "\u21BD",
  lharu: "\u21BC",
  lharul: "\u296A",
  lhblk: "\u2584",
  LJcy: "\u0409",
  ljcy: "\u0459",
  Ll: "\u22D8",
  ll: "\u226A",
  llarr: "\u21C7",
  llcorner: "\u231E",
  Lleftarrow: "\u21DA",
  llhard: "\u296B",
  lltri: "\u25FA",
  Lmidot: "\u013F",
  lmidot: "\u0140",
  lmoust: "\u23B0",
  lmoustache: "\u23B0",
  lnap: "\u2A89",
  lnapprox: "\u2A89",
  lnE: "\u2268",
  lne: "\u2A87",
  lneq: "\u2A87",
  lneqq: "\u2268",
  lnsim: "\u22E6",
  loang: "\u27EC",
  loarr: "\u21FD",
  lobrk: "\u27E6",
  LongLeftArrow: "\u27F5",
  Longleftarrow: "\u27F8",
  longleftarrow: "\u27F5",
  LongLeftRightArrow: "\u27F7",
  Longleftrightarrow: "\u27FA",
  longleftrightarrow: "\u27F7",
  longmapsto: "\u27FC",
  LongRightArrow: "\u27F6",
  Longrightarrow: "\u27F9",
  longrightarrow: "\u27F6",
  looparrowleft: "\u21AB",
  looparrowright: "\u21AC",
  lopar: "\u2985",
  Lopf: "\uD835\uDD43",
  lopf: "\uD835\uDD5D",
  loplus: "\u2A2D",
  lotimes: "\u2A34",
  lowast: "\u2217",
  lowbar: "_",
  LowerLeftArrow: "\u2199",
  LowerRightArrow: "\u2198",
  loz: "\u25CA",
  lozenge: "\u25CA",
  lozf: "\u29EB",
  lpar: "(",
  lparlt: "\u2993",
  lrarr: "\u21C6",
  lrcorner: "\u231F",
  lrhar: "\u21CB",
  lrhard: "\u296D",
  lrm: "\u200E",
  lrtri: "\u22BF",
  lsaquo: "\u2039",
  Lscr: "\u2112",
  lscr: "\uD835\uDCC1",
  Lsh: "\u21B0",
  lsh: "\u21B0",
  lsim: "\u2272",
  lsime: "\u2A8D",
  lsimg: "\u2A8F",
  lsqb: "[",
  lsquo: "\u2018",
  lsquor: "\u201A",
  Lstrok: "\u0141",
  lstrok: "\u0142",
  Lt: "\u226A",
  LT: "<",
  lt: "<",
  ltcc: "\u2AA6",
  ltcir: "\u2A79",
  ltdot: "\u22D6",
  lthree: "\u22CB",
  ltimes: "\u22C9",
  ltlarr: "\u2976",
  ltquest: "\u2A7B",
  ltri: "\u25C3",
  ltrie: "\u22B4",
  ltrif: "\u25C2",
  ltrPar: "\u2996",
  lurdshar: "\u294A",
  luruhar: "\u2966",
  lvertneqq: "\u2268\uFE00",
  lvnE: "\u2268\uFE00",
  macr: "\xAF",
  male: "\u2642",
  malt: "\u2720",
  maltese: "\u2720",
  Map: "\u2905",
  map: "\u21A6",
  mapsto: "\u21A6",
  mapstodown: "\u21A7",
  mapstoleft: "\u21A4",
  mapstoup: "\u21A5",
  marker: "\u25AE",
  mcomma: "\u2A29",
  Mcy: "\u041C",
  mcy: "\u043C",
  mdash: "\u2014",
  mDDot: "\u223A",
  measuredangle: "\u2221",
  MediumSpace: "\u205F",
  Mellintrf: "\u2133",
  Mfr: "\uD835\uDD10",
  mfr: "\uD835\uDD2A",
  mho: "\u2127",
  micro: "\xB5",
  mid: "\u2223",
  midast: "*",
  midcir: "\u2AF0",
  middot: "\xB7",
  minus: "\u2212",
  minusb: "\u229F",
  minusd: "\u2238",
  minusdu: "\u2A2A",
  MinusPlus: "\u2213",
  mlcp: "\u2ADB",
  mldr: "\u2026",
  mnplus: "\u2213",
  models: "\u22A7",
  Mopf: "\uD835\uDD44",
  mopf: "\uD835\uDD5E",
  mp: "\u2213",
  Mscr: "\u2133",
  mscr: "\uD835\uDCC2",
  mstpos: "\u223E",
  Mu: "\u039C",
  mu: "\u03BC",
  multimap: "\u22B8",
  mumap: "\u22B8",
  nabla: "\u2207",
  Nacute: "\u0143",
  nacute: "\u0144",
  nang: "\u2220\u20D2",
  nap: "\u2249",
  napE: "\u2A70\u0338",
  napid: "\u224B\u0338",
  napos: "\u0149",
  napprox: "\u2249",
  natur: "\u266E",
  natural: "\u266E",
  naturals: "\u2115",
  nbsp: "\xA0",
  nbump: "\u224E\u0338",
  nbumpe: "\u224F\u0338",
  ncap: "\u2A43",
  Ncaron: "\u0147",
  ncaron: "\u0148",
  Ncedil: "\u0145",
  ncedil: "\u0146",
  ncong: "\u2247",
  ncongdot: "\u2A6D\u0338",
  ncup: "\u2A42",
  Ncy: "\u041D",
  ncy: "\u043D",
  ndash: "\u2013",
  ne: "\u2260",
  nearhk: "\u2924",
  neArr: "\u21D7",
  nearr: "\u2197",
  nearrow: "\u2197",
  nedot: "\u2250\u0338",
  NegativeMediumSpace: "\u200B",
  NegativeThickSpace: "\u200B",
  NegativeThinSpace: "\u200B",
  NegativeVeryThinSpace: "\u200B",
  nequiv: "\u2262",
  nesear: "\u2928",
  nesim: "\u2242\u0338",
  NestedGreaterGreater: "\u226B",
  NestedLessLess: "\u226A",
  NewLine: "\n",
  nexist: "\u2204",
  nexists: "\u2204",
  Nfr: "\uD835\uDD11",
  nfr: "\uD835\uDD2B",
  ngE: "\u2267\u0338",
  nge: "\u2271",
  ngeq: "\u2271",
  ngeqq: "\u2267\u0338",
  ngeqslant: "\u2A7E\u0338",
  nges: "\u2A7E\u0338",
  nGg: "\u22D9\u0338",
  ngsim: "\u2275",
  nGt: "\u226B\u20D2",
  ngt: "\u226F",
  ngtr: "\u226F",
  nGtv: "\u226B\u0338",
  nhArr: "\u21CE",
  nharr: "\u21AE",
  nhpar: "\u2AF2",
  ni: "\u220B",
  nis: "\u22FC",
  nisd: "\u22FA",
  niv: "\u220B",
  NJcy: "\u040A",
  njcy: "\u045A",
  nlArr: "\u21CD",
  nlarr: "\u219A",
  nldr: "\u2025",
  nlE: "\u2266\u0338",
  nle: "\u2270",
  nLeftarrow: "\u21CD",
  nleftarrow: "\u219A",
  nLeftrightarrow: "\u21CE",
  nleftrightarrow: "\u21AE",
  nleq: "\u2270",
  nleqq: "\u2266\u0338",
  nleqslant: "\u2A7D\u0338",
  nles: "\u2A7D\u0338",
  nless: "\u226E",
  nLl: "\u22D8\u0338",
  nlsim: "\u2274",
  nLt: "\u226A\u20D2",
  nlt: "\u226E",
  nltri: "\u22EA",
  nltrie: "\u22EC",
  nLtv: "\u226A\u0338",
  nmid: "\u2224",
  NoBreak: "\u2060",
  NonBreakingSpace: "\xA0",
  Nopf: "\u2115",
  nopf: "\uD835\uDD5F",
  Not: "\u2AEC",
  not: "\xAC",
  NotCongruent: "\u2262",
  NotCupCap: "\u226D",
  NotDoubleVerticalBar: "\u2226",
  NotElement: "\u2209",
  NotEqual: "\u2260",
  NotEqualTilde: "\u2242\u0338",
  NotExists: "\u2204",
  NotGreater: "\u226F",
  NotGreaterEqual: "\u2271",
  NotGreaterFullEqual: "\u2267\u0338",
  NotGreaterGreater: "\u226B\u0338",
  NotGreaterLess: "\u2279",
  NotGreaterSlantEqual: "\u2A7E\u0338",
  NotGreaterTilde: "\u2275",
  NotHumpDownHump: "\u224E\u0338",
  NotHumpEqual: "\u224F\u0338",
  notin: "\u2209",
  notindot: "\u22F5\u0338",
  notinE: "\u22F9\u0338",
  notinva: "\u2209",
  notinvb: "\u22F7",
  notinvc: "\u22F6",
  NotLeftTriangle: "\u22EA",
  NotLeftTriangleBar: "\u29CF\u0338",
  NotLeftTriangleEqual: "\u22EC",
  NotLess: "\u226E",
  NotLessEqual: "\u2270",
  NotLessGreater: "\u2278",
  NotLessLess: "\u226A\u0338",
  NotLessSlantEqual: "\u2A7D\u0338",
  NotLessTilde: "\u2274",
  NotNestedGreaterGreater: "\u2AA2\u0338",
  NotNestedLessLess: "\u2AA1\u0338",
  notni: "\u220C",
  notniva: "\u220C",
  notnivb: "\u22FE",
  notnivc: "\u22FD",
  NotPrecedes: "\u2280",
  NotPrecedesEqual: "\u2AAF\u0338",
  NotPrecedesSlantEqual: "\u22E0",
  NotReverseElement: "\u220C",
  NotRightTriangle: "\u22EB",
  NotRightTriangleBar: "\u29D0\u0338",
  NotRightTriangleEqual: "\u22ED",
  NotSquareSubset: "\u228F\u0338",
  NotSquareSubsetEqual: "\u22E2",
  NotSquareSuperset: "\u2290\u0338",
  NotSquareSupersetEqual: "\u22E3",
  NotSubset: "\u2282\u20D2",
  NotSubsetEqual: "\u2288",
  NotSucceeds: "\u2281",
  NotSucceedsEqual: "\u2AB0\u0338",
  NotSucceedsSlantEqual: "\u22E1",
  NotSucceedsTilde: "\u227F\u0338",
  NotSuperset: "\u2283\u20D2",
  NotSupersetEqual: "\u2289",
  NotTilde: "\u2241",
  NotTildeEqual: "\u2244",
  NotTildeFullEqual: "\u2247",
  NotTildeTilde: "\u2249",
  NotVerticalBar: "\u2224",
  npar: "\u2226",
  nparallel: "\u2226",
  nparsl: "\u2AFD\u20E5",
  npart: "\u2202\u0338",
  npolint: "\u2A14",
  npr: "\u2280",
  nprcue: "\u22E0",
  npre: "\u2AAF\u0338",
  nprec: "\u2280",
  npreceq: "\u2AAF\u0338",
  nrArr: "\u21CF",
  nrarr: "\u219B",
  nrarrc: "\u2933\u0338",
  nrarrw: "\u219D\u0338",
  nRightarrow: "\u21CF",
  nrightarrow: "\u219B",
  nrtri: "\u22EB",
  nrtrie: "\u22ED",
  nsc: "\u2281",
  nsccue: "\u22E1",
  nsce: "\u2AB0\u0338",
  Nscr: "\uD835\uDCA9",
  nscr: "\uD835\uDCC3",
  nshortmid: "\u2224",
  nshortparallel: "\u2226",
  nsim: "\u2241",
  nsime: "\u2244",
  nsimeq: "\u2244",
  nsmid: "\u2224",
  nspar: "\u2226",
  nsqsube: "\u22E2",
  nsqsupe: "\u22E3",
  nsub: "\u2284",
  nsubE: "\u2AC5\u0338",
  nsube: "\u2288",
  nsubset: "\u2282\u20D2",
  nsubseteq: "\u2288",
  nsubseteqq: "\u2AC5\u0338",
  nsucc: "\u2281",
  nsucceq: "\u2AB0\u0338",
  nsup: "\u2285",
  nsupE: "\u2AC6\u0338",
  nsupe: "\u2289",
  nsupset: "\u2283\u20D2",
  nsupseteq: "\u2289",
  nsupseteqq: "\u2AC6\u0338",
  ntgl: "\u2279",
  Ntilde: "\xD1",
  ntilde: "\xF1",
  ntlg: "\u2278",
  ntriangleleft: "\u22EA",
  ntrianglelefteq: "\u22EC",
  ntriangleright: "\u22EB",
  ntrianglerighteq: "\u22ED",
  Nu: "\u039D",
  nu: "\u03BD",
  num: "#",
  numero: "\u2116",
  numsp: "\u2007",
  nvap: "\u224D\u20D2",
  nVDash: "\u22AF",
  nVdash: "\u22AE",
  nvDash: "\u22AD",
  nvdash: "\u22AC",
  nvge: "\u2265\u20D2",
  nvgt: ">\u20D2",
  nvHarr: "\u2904",
  nvinfin: "\u29DE",
  nvlArr: "\u2902",
  nvle: "\u2264\u20D2",
  nvlt: "<\u20D2",
  nvltrie: "\u22B4\u20D2",
  nvrArr: "\u2903",
  nvrtrie: "\u22B5\u20D2",
  nvsim: "\u223C\u20D2",
  nwarhk: "\u2923",
  nwArr: "\u21D6",
  nwarr: "\u2196",
  nwarrow: "\u2196",
  nwnear: "\u2927",
  Oacute: "\xD3",
  oacute: "\xF3",
  oast: "\u229B",
  ocir: "\u229A",
  Ocirc: "\xD4",
  ocirc: "\xF4",
  Ocy: "\u041E",
  ocy: "\u043E",
  odash: "\u229D",
  Odblac: "\u0150",
  odblac: "\u0151",
  odiv: "\u2A38",
  odot: "\u2299",
  odsold: "\u29BC",
  OElig: "\u0152",
  oelig: "\u0153",
  ofcir: "\u29BF",
  Ofr: "\uD835\uDD12",
  ofr: "\uD835\uDD2C",
  ogon: "\u02DB",
  Ograve: "\xD2",
  ograve: "\xF2",
  ogt: "\u29C1",
  ohbar: "\u29B5",
  ohm: "\u03A9",
  oint: "\u222E",
  olarr: "\u21BA",
  olcir: "\u29BE",
  olcross: "\u29BB",
  oline: "\u203E",
  olt: "\u29C0",
  Omacr: "\u014C",
  omacr: "\u014D",
  Omega: "\u03A9",
  omega: "\u03C9",
  Omicron: "\u039F",
  omicron: "\u03BF",
  omid: "\u29B6",
  ominus: "\u2296",
  Oopf: "\uD835\uDD46",
  oopf: "\uD835\uDD60",
  opar: "\u29B7",
  OpenCurlyDoubleQuote: "\u201C",
  OpenCurlyQuote: "\u2018",
  operp: "\u29B9",
  oplus: "\u2295",
  Or: "\u2A54",
  or: "\u2228",
  orarr: "\u21BB",
  ord: "\u2A5D",
  order: "\u2134",
  orderof: "\u2134",
  ordf: "\xAA",
  ordm: "\xBA",
  origof: "\u22B6",
  oror: "\u2A56",
  orslope: "\u2A57",
  orv: "\u2A5B",
  oS: "\u24C8",
  Oscr: "\uD835\uDCAA",
  oscr: "\u2134",
  Oslash: "\xD8",
  oslash: "\xF8",
  osol: "\u2298",
  Otilde: "\xD5",
  otilde: "\xF5",
  Otimes: "\u2A37",
  otimes: "\u2297",
  otimesas: "\u2A36",
  Ouml: "\xD6",
  ouml: "\xF6",
  ovbar: "\u233D",
  OverBar: "\u203E",
  OverBrace: "\u23DE",
  OverBracket: "\u23B4",
  OverParenthesis: "\u23DC",
  par: "\u2225",
  para: "\xB6",
  parallel: "\u2225",
  parsim: "\u2AF3",
  parsl: "\u2AFD",
  part: "\u2202",
  PartialD: "\u2202",
  Pcy: "\u041F",
  pcy: "\u043F",
  percnt: "%",
  period: ".",
  permil: "\u2030",
  perp: "\u22A5",
  pertenk: "\u2031",
  Pfr: "\uD835\uDD13",
  pfr: "\uD835\uDD2D",
  Phi: "\u03A6",
  phi: "\u03C6",
  phiv: "\u03D5",
  phmmat: "\u2133",
  phone: "\u260E",
  Pi: "\u03A0",
  pi: "\u03C0",
  pitchfork: "\u22D4",
  piv: "\u03D6",
  planck: "\u210F",
  planckh: "\u210E",
  plankv: "\u210F",
  plus: "+",
  plusacir: "\u2A23",
  plusb: "\u229E",
  pluscir: "\u2A22",
  plusdo: "\u2214",
  plusdu: "\u2A25",
  pluse: "\u2A72",
  PlusMinus: "\xB1",
  plusmn: "\xB1",
  plussim: "\u2A26",
  plustwo: "\u2A27",
  pm: "\xB1",
  Poincareplane: "\u210C",
  pointint: "\u2A15",
  Popf: "\u2119",
  popf: "\uD835\uDD61",
  pound: "\xA3",
  Pr: "\u2ABB",
  pr: "\u227A",
  prap: "\u2AB7",
  prcue: "\u227C",
  prE: "\u2AB3",
  pre: "\u2AAF",
  prec: "\u227A",
  precapprox: "\u2AB7",
  preccurlyeq: "\u227C",
  Precedes: "\u227A",
  PrecedesEqual: "\u2AAF",
  PrecedesSlantEqual: "\u227C",
  PrecedesTilde: "\u227E",
  preceq: "\u2AAF",
  precnapprox: "\u2AB9",
  precneqq: "\u2AB5",
  precnsim: "\u22E8",
  precsim: "\u227E",
  Prime: "\u2033",
  prime: "\u2032",
  primes: "\u2119",
  prnap: "\u2AB9",
  prnE: "\u2AB5",
  prnsim: "\u22E8",
  prod: "\u220F",
  Product: "\u220F",
  profalar: "\u232E",
  profline: "\u2312",
  profsurf: "\u2313",
  prop: "\u221D",
  Proportion: "\u2237",
  Proportional: "\u221D",
  propto: "\u221D",
  prsim: "\u227E",
  prurel: "\u22B0",
  Pscr: "\uD835\uDCAB",
  pscr: "\uD835\uDCC5",
  Psi: "\u03A8",
  psi: "\u03C8",
  puncsp: "\u2008",
  Qfr: "\uD835\uDD14",
  qfr: "\uD835\uDD2E",
  qint: "\u2A0C",
  Qopf: "\u211A",
  qopf: "\uD835\uDD62",
  qprime: "\u2057",
  Qscr: "\uD835\uDCAC",
  qscr: "\uD835\uDCC6",
  quaternions: "\u210D",
  quatint: "\u2A16",
  quest: "?",
  questeq: "\u225F",
  QUOT: "\"",
  quot: "\"",
  rAarr: "\u21DB",
  race: "\u223D\u0331",
  Racute: "\u0154",
  racute: "\u0155",
  radic: "\u221A",
  raemptyv: "\u29B3",
  Rang: "\u27EB",
  rang: "\u27E9",
  rangd: "\u2992",
  range: "\u29A5",
  rangle: "\u27E9",
  raquo: "\xBB",
  Rarr: "\u21A0",
  rArr: "\u21D2",
  rarr: "\u2192",
  rarrap: "\u2975",
  rarrb: "\u21E5",
  rarrbfs: "\u2920",
  rarrc: "\u2933",
  rarrfs: "\u291E",
  rarrhk: "\u21AA",
  rarrlp: "\u21AC",
  rarrpl: "\u2945",
  rarrsim: "\u2974",
  Rarrtl: "\u2916",
  rarrtl: "\u21A3",
  rarrw: "\u219D",
  rAtail: "\u291C",
  ratail: "\u291A",
  ratio: "\u2236",
  rationals: "\u211A",
  RBarr: "\u2910",
  rBarr: "\u290F",
  rbarr: "\u290D",
  rbbrk: "\u2773",
  rbrace: "}",
  rbrack: "]",
  rbrke: "\u298C",
  rbrksld: "\u298E",
  rbrkslu: "\u2990",
  Rcaron: "\u0158",
  rcaron: "\u0159",
  Rcedil: "\u0156",
  rcedil: "\u0157",
  rceil: "\u2309",
  rcub: "}",
  Rcy: "\u0420",
  rcy: "\u0440",
  rdca: "\u2937",
  rdldhar: "\u2969",
  rdquo: "\u201D",
  rdquor: "\u201D",
  rdsh: "\u21B3",
  Re: "\u211C",
  real: "\u211C",
  realine: "\u211B",
  realpart: "\u211C",
  reals: "\u211D",
  rect: "\u25AD",
  REG: "\xAE",
  reg: "\xAE",
  ReverseElement: "\u220B",
  ReverseEquilibrium: "\u21CB",
  ReverseUpEquilibrium: "\u296F",
  rfisht: "\u297D",
  rfloor: "\u230B",
  Rfr: "\u211C",
  rfr: "\uD835\uDD2F",
  rHar: "\u2964",
  rhard: "\u21C1",
  rharu: "\u21C0",
  rharul: "\u296C",
  Rho: "\u03A1",
  rho: "\u03C1",
  rhov: "\u03F1",
  RightAngleBracket: "\u27E9",
  RightArrow: "\u2192",
  Rightarrow: "\u21D2",
  rightarrow: "\u2192",
  RightArrowBar: "\u21E5",
  RightArrowLeftArrow: "\u21C4",
  rightarrowtail: "\u21A3",
  RightCeiling: "\u2309",
  RightDoubleBracket: "\u27E7",
  RightDownTeeVector: "\u295D",
  RightDownVector: "\u21C2",
  RightDownVectorBar: "\u2955",
  RightFloor: "\u230B",
  rightharpoondown: "\u21C1",
  rightharpoonup: "\u21C0",
  rightleftarrows: "\u21C4",
  rightleftharpoons: "\u21CC",
  rightrightarrows: "\u21C9",
  rightsquigarrow: "\u219D",
  RightTee: "\u22A2",
  RightTeeArrow: "\u21A6",
  RightTeeVector: "\u295B",
  rightthreetimes: "\u22CC",
  RightTriangle: "\u22B3",
  RightTriangleBar: "\u29D0",
  RightTriangleEqual: "\u22B5",
  RightUpDownVector: "\u294F",
  RightUpTeeVector: "\u295C",
  RightUpVector: "\u21BE",
  RightUpVectorBar: "\u2954",
  RightVector: "\u21C0",
  RightVectorBar: "\u2953",
  ring: "\u02DA",
  risingdotseq: "\u2253",
  rlarr: "\u21C4",
  rlhar: "\u21CC",
  rlm: "\u200F",
  rmoust: "\u23B1",
  rmoustache: "\u23B1",
  rnmid: "\u2AEE",
  roang: "\u27ED",
  roarr: "\u21FE",
  robrk: "\u27E7",
  ropar: "\u2986",
  Ropf: "\u211D",
  ropf: "\uD835\uDD63",
  roplus: "\u2A2E",
  rotimes: "\u2A35",
  RoundImplies: "\u2970",
  rpar: ")",
  rpargt: "\u2994",
  rppolint: "\u2A12",
  rrarr: "\u21C9",
  Rrightarrow: "\u21DB",
  rsaquo: "\u203A",
  Rscr: "\u211B",
  rscr: "\uD835\uDCC7",
  Rsh: "\u21B1",
  rsh: "\u21B1",
  rsqb: "]",
  rsquo: "\u2019",
  rsquor: "\u2019",
  rthree: "\u22CC",
  rtimes: "\u22CA",
  rtri: "\u25B9",
  rtrie: "\u22B5",
  rtrif: "\u25B8",
  rtriltri: "\u29CE",
  RuleDelayed: "\u29F4",
  ruluhar: "\u2968",
  rx: "\u211E",
  Sacute: "\u015A",
  sacute: "\u015B",
  sbquo: "\u201A",
  Sc: "\u2ABC",
  sc: "\u227B",
  scap: "\u2AB8",
  Scaron: "\u0160",
  scaron: "\u0161",
  sccue: "\u227D",
  scE: "\u2AB4",
  sce: "\u2AB0",
  Scedil: "\u015E",
  scedil: "\u015F",
  Scirc: "\u015C",
  scirc: "\u015D",
  scnap: "\u2ABA",
  scnE: "\u2AB6",
  scnsim: "\u22E9",
  scpolint: "\u2A13",
  scsim: "\u227F",
  Scy: "\u0421",
  scy: "\u0441",
  sdot: "\u22C5",
  sdotb: "\u22A1",
  sdote: "\u2A66",
  searhk: "\u2925",
  seArr: "\u21D8",
  searr: "\u2198",
  searrow: "\u2198",
  sect: "\xA7",
  semi: ";",
  seswar: "\u2929",
  setminus: "\u2216",
  setmn: "\u2216",
  sext: "\u2736",
  Sfr: "\uD835\uDD16",
  sfr: "\uD835\uDD30",
  sfrown: "\u2322",
  sharp: "\u266F",
  SHCHcy: "\u0429",
  shchcy: "\u0449",
  SHcy: "\u0428",
  shcy: "\u0448",
  ShortDownArrow: "\u2193",
  ShortLeftArrow: "\u2190",
  shortmid: "\u2223",
  shortparallel: "\u2225",
  ShortRightArrow: "\u2192",
  ShortUpArrow: "\u2191",
  shy: "\xAD",
  Sigma: "\u03A3",
  sigma: "\u03C3",
  sigmaf: "\u03C2",
  sigmav: "\u03C2",
  sim: "\u223C",
  simdot: "\u2A6A",
  sime: "\u2243",
  simeq: "\u2243",
  simg: "\u2A9E",
  simgE: "\u2AA0",
  siml: "\u2A9D",
  simlE: "\u2A9F",
  simne: "\u2246",
  simplus: "\u2A24",
  simrarr: "\u2972",
  slarr: "\u2190",
  SmallCircle: "\u2218",
  smallsetminus: "\u2216",
  smashp: "\u2A33",
  smeparsl: "\u29E4",
  smid: "\u2223",
  smile: "\u2323",
  smt: "\u2AAA",
  smte: "\u2AAC",
  smtes: "\u2AAC\uFE00",
  SOFTcy: "\u042C",
  softcy: "\u044C",
  sol: "/",
  solb: "\u29C4",
  solbar: "\u233F",
  Sopf: "\uD835\uDD4A",
  sopf: "\uD835\uDD64",
  spades: "\u2660",
  spadesuit: "\u2660",
  spar: "\u2225",
  sqcap: "\u2293",
  sqcaps: "\u2293\uFE00",
  sqcup: "\u2294",
  sqcups: "\u2294\uFE00",
  Sqrt: "\u221A",
  sqsub: "\u228F",
  sqsube: "\u2291",
  sqsubset: "\u228F",
  sqsubseteq: "\u2291",
  sqsup: "\u2290",
  sqsupe: "\u2292",
  sqsupset: "\u2290",
  sqsupseteq: "\u2292",
  squ: "\u25A1",
  Square: "\u25A1",
  square: "\u25A1",
  SquareIntersection: "\u2293",
  SquareSubset: "\u228F",
  SquareSubsetEqual: "\u2291",
  SquareSuperset: "\u2290",
  SquareSupersetEqual: "\u2292",
  SquareUnion: "\u2294",
  squarf: "\u25AA",
  squf: "\u25AA",
  srarr: "\u2192",
  Sscr: "\uD835\uDCAE",
  sscr: "\uD835\uDCC8",
  ssetmn: "\u2216",
  ssmile: "\u2323",
  sstarf: "\u22C6",
  Star: "\u22C6",
  star: "\u2606",
  starf: "\u2605",
  straightepsilon: "\u03F5",
  straightphi: "\u03D5",
  strns: "\xAF",
  Sub: "\u22D0",
  sub: "\u2282",
  subdot: "\u2ABD",
  subE: "\u2AC5",
  sube: "\u2286",
  subedot: "\u2AC3",
  submult: "\u2AC1",
  subnE: "\u2ACB",
  subne: "\u228A",
  subplus: "\u2ABF",
  subrarr: "\u2979",
  Subset: "\u22D0",
  subset: "\u2282",
  subseteq: "\u2286",
  subseteqq: "\u2AC5",
  SubsetEqual: "\u2286",
  subsetneq: "\u228A",
  subsetneqq: "\u2ACB",
  subsim: "\u2AC7",
  subsub: "\u2AD5",
  subsup: "\u2AD3",
  succ: "\u227B",
  succapprox: "\u2AB8",
  succcurlyeq: "\u227D",
  Succeeds: "\u227B",
  SucceedsEqual: "\u2AB0",
  SucceedsSlantEqual: "\u227D",
  SucceedsTilde: "\u227F",
  succeq: "\u2AB0",
  succnapprox: "\u2ABA",
  succneqq: "\u2AB6",
  succnsim: "\u22E9",
  succsim: "\u227F",
  SuchThat: "\u220B",
  Sum: "\u2211",
  sum: "\u2211",
  sung: "\u266A",
  Sup: "\u22D1",
  sup: "\u2283",
  sup1: "\xB9",
  sup2: "\xB2",
  sup3: "\xB3",
  supdot: "\u2ABE",
  supdsub: "\u2AD8",
  supE: "\u2AC6",
  supe: "\u2287",
  supedot: "\u2AC4",
  Superset: "\u2283",
  SupersetEqual: "\u2287",
  suphsol: "\u27C9",
  suphsub: "\u2AD7",
  suplarr: "\u297B",
  supmult: "\u2AC2",
  supnE: "\u2ACC",
  supne: "\u228B",
  supplus: "\u2AC0",
  Supset: "\u22D1",
  supset: "\u2283",
  supseteq: "\u2287",
  supseteqq: "\u2AC6",
  supsetneq: "\u228B",
  supsetneqq: "\u2ACC",
  supsim: "\u2AC8",
  supsub: "\u2AD4",
  supsup: "\u2AD6",
  swarhk: "\u2926",
  swArr: "\u21D9",
  swarr: "\u2199",
  swarrow: "\u2199",
  swnwar: "\u292A",
  szlig: "\xDF",
  Tab: "\t",
  target: "\u2316",
  Tau: "\u03A4",
  tau: "\u03C4",
  tbrk: "\u23B4",
  Tcaron: "\u0164",
  tcaron: "\u0165",
  Tcedil: "\u0162",
  tcedil: "\u0163",
  Tcy: "\u0422",
  tcy: "\u0442",
  tdot: "\u20DB",
  telrec: "\u2315",
  Tfr: "\uD835\uDD17",
  tfr: "\uD835\uDD31",
  there4: "\u2234",
  Therefore: "\u2234",
  therefore: "\u2234",
  Theta: "\u0398",
  theta: "\u03B8",
  thetasym: "\u03D1",
  thetav: "\u03D1",
  thickapprox: "\u2248",
  thicksim: "\u223C",
  ThickSpace: "\u205F\u200A",
  thinsp: "\u2009",
  ThinSpace: "\u2009",
  thkap: "\u2248",
  thksim: "\u223C",
  THORN: "\xDE",
  thorn: "\xFE",
  Tilde: "\u223C",
  tilde: "\u02DC",
  TildeEqual: "\u2243",
  TildeFullEqual: "\u2245",
  TildeTilde: "\u2248",
  times: "\xD7",
  timesb: "\u22A0",
  timesbar: "\u2A31",
  timesd: "\u2A30",
  tint: "\u222D",
  toea: "\u2928",
  top: "\u22A4",
  topbot: "\u2336",
  topcir: "\u2AF1",
  Topf: "\uD835\uDD4B",
  topf: "\uD835\uDD65",
  topfork: "\u2ADA",
  tosa: "\u2929",
  tprime: "\u2034",
  TRADE: "\u2122",
  trade: "\u2122",
  triangle: "\u25B5",
  triangledown: "\u25BF",
  triangleleft: "\u25C3",
  trianglelefteq: "\u22B4",
  triangleq: "\u225C",
  triangleright: "\u25B9",
  trianglerighteq: "\u22B5",
  tridot: "\u25EC",
  trie: "\u225C",
  triminus: "\u2A3A",
  TripleDot: "\u20DB",
  triplus: "\u2A39",
  trisb: "\u29CD",
  tritime: "\u2A3B",
  trpezium: "\u23E2",
  Tscr: "\uD835\uDCAF",
  tscr: "\uD835\uDCC9",
  TScy: "\u0426",
  tscy: "\u0446",
  TSHcy: "\u040B",
  tshcy: "\u045B",
  Tstrok: "\u0166",
  tstrok: "\u0167",
  twixt: "\u226C",
  twoheadleftarrow: "\u219E",
  twoheadrightarrow: "\u21A0",
  Uacute: "\xDA",
  uacute: "\xFA",
  Uarr: "\u219F",
  uArr: "\u21D1",
  uarr: "\u2191",
  Uarrocir: "\u2949",
  Ubrcy: "\u040E",
  ubrcy: "\u045E",
  Ubreve: "\u016C",
  ubreve: "\u016D",
  Ucirc: "\xDB",
  ucirc: "\xFB",
  Ucy: "\u0423",
  ucy: "\u0443",
  udarr: "\u21C5",
  Udblac: "\u0170",
  udblac: "\u0171",
  udhar: "\u296E",
  ufisht: "\u297E",
  Ufr: "\uD835\uDD18",
  ufr: "\uD835\uDD32",
  Ugrave: "\xD9",
  ugrave: "\xF9",
  uHar: "\u2963",
  uharl: "\u21BF",
  uharr: "\u21BE",
  uhblk: "\u2580",
  ulcorn: "\u231C",
  ulcorner: "\u231C",
  ulcrop: "\u230F",
  ultri: "\u25F8",
  Umacr: "\u016A",
  umacr: "\u016B",
  uml: "\xA8",
  UnderBar: "_",
  UnderBrace: "\u23DF",
  UnderBracket: "\u23B5",
  UnderParenthesis: "\u23DD",
  Union: "\u22C3",
  UnionPlus: "\u228E",
  Uogon: "\u0172",
  uogon: "\u0173",
  Uopf: "\uD835\uDD4C",
  uopf: "\uD835\uDD66",
  UpArrow: "\u2191",
  Uparrow: "\u21D1",
  uparrow: "\u2191",
  UpArrowBar: "\u2912",
  UpArrowDownArrow: "\u21C5",
  UpDownArrow: "\u2195",
  Updownarrow: "\u21D5",
  updownarrow: "\u2195",
  UpEquilibrium: "\u296E",
  upharpoonleft: "\u21BF",
  upharpoonright: "\u21BE",
  uplus: "\u228E",
  UpperLeftArrow: "\u2196",
  UpperRightArrow: "\u2197",
  Upsi: "\u03D2",
  upsi: "\u03C5",
  upsih: "\u03D2",
  Upsilon: "\u03A5",
  upsilon: "\u03C5",
  UpTee: "\u22A5",
  UpTeeArrow: "\u21A5",
  upuparrows: "\u21C8",
  urcorn: "\u231D",
  urcorner: "\u231D",
  urcrop: "\u230E",
  Uring: "\u016E",
  uring: "\u016F",
  urtri: "\u25F9",
  Uscr: "\uD835\uDCB0",
  uscr: "\uD835\uDCCA",
  utdot: "\u22F0",
  Utilde: "\u0168",
  utilde: "\u0169",
  utri: "\u25B5",
  utrif: "\u25B4",
  uuarr: "\u21C8",
  Uuml: "\xDC",
  uuml: "\xFC",
  uwangle: "\u29A7",
  vangrt: "\u299C",
  varepsilon: "\u03F5",
  varkappa: "\u03F0",
  varnothing: "\u2205",
  varphi: "\u03D5",
  varpi: "\u03D6",
  varpropto: "\u221D",
  vArr: "\u21D5",
  varr: "\u2195",
  varrho: "\u03F1",
  varsigma: "\u03C2",
  varsubsetneq: "\u228A\uFE00",
  varsubsetneqq: "\u2ACB\uFE00",
  varsupsetneq: "\u228B\uFE00",
  varsupsetneqq: "\u2ACC\uFE00",
  vartheta: "\u03D1",
  vartriangleleft: "\u22B2",
  vartriangleright: "\u22B3",
  Vbar: "\u2AEB",
  vBar: "\u2AE8",
  vBarv: "\u2AE9",
  Vcy: "\u0412",
  vcy: "\u0432",
  VDash: "\u22AB",
  Vdash: "\u22A9",
  vDash: "\u22A8",
  vdash: "\u22A2",
  Vdashl: "\u2AE6",
  Vee: "\u22C1",
  vee: "\u2228",
  veebar: "\u22BB",
  veeeq: "\u225A",
  vellip: "\u22EE",
  Verbar: "\u2016",
  verbar: "|",
  Vert: "\u2016",
  vert: "|",
  VerticalBar: "\u2223",
  VerticalLine: "|",
  VerticalSeparator: "\u2758",
  VerticalTilde: "\u2240",
  VeryThinSpace: "\u200A",
  Vfr: "\uD835\uDD19",
  vfr: "\uD835\uDD33",
  vltri: "\u22B2",
  vnsub: "\u2282\u20D2",
  vnsup: "\u2283\u20D2",
  Vopf: "\uD835\uDD4D",
  vopf: "\uD835\uDD67",
  vprop: "\u221D",
  vrtri: "\u22B3",
  Vscr: "\uD835\uDCB1",
  vscr: "\uD835\uDCCB",
  vsubnE: "\u2ACB\uFE00",
  vsubne: "\u228A\uFE00",
  vsupnE: "\u2ACC\uFE00",
  vsupne: "\u228B\uFE00",
  Vvdash: "\u22AA",
  vzigzag: "\u299A",
  Wcirc: "\u0174",
  wcirc: "\u0175",
  wedbar: "\u2A5F",
  Wedge: "\u22C0",
  wedge: "\u2227",
  wedgeq: "\u2259",
  weierp: "\u2118",
  Wfr: "\uD835\uDD1A",
  wfr: "\uD835\uDD34",
  Wopf: "\uD835\uDD4E",
  wopf: "\uD835\uDD68",
  wp: "\u2118",
  wr: "\u2240",
  wreath: "\u2240",
  Wscr: "\uD835\uDCB2",
  wscr: "\uD835\uDCCC",
  xcap: "\u22C2",
  xcirc: "\u25EF",
  xcup: "\u22C3",
  xdtri: "\u25BD",
  Xfr: "\uD835\uDD1B",
  xfr: "\uD835\uDD35",
  xhArr: "\u27FA",
  xharr: "\u27F7",
  Xi: "\u039E",
  xi: "\u03BE",
  xlArr: "\u27F8",
  xlarr: "\u27F5",
  xmap: "\u27FC",
  xnis: "\u22FB",
  xodot: "\u2A00",
  Xopf: "\uD835\uDD4F",
  xopf: "\uD835\uDD69",
  xoplus: "\u2A01",
  xotime: "\u2A02",
  xrArr: "\u27F9",
  xrarr: "\u27F6",
  Xscr: "\uD835\uDCB3",
  xscr: "\uD835\uDCCD",
  xsqcup: "\u2A06",
  xuplus: "\u2A04",
  xutri: "\u25B3",
  xvee: "\u22C1",
  xwedge: "\u22C0",
  Yacute: "\xDD",
  yacute: "\xFD",
  YAcy: "\u042F",
  yacy: "\u044F",
  Ycirc: "\u0176",
  ycirc: "\u0177",
  Ycy: "\u042B",
  ycy: "\u044B",
  yen: "\xA5",
  Yfr: "\uD835\uDD1C",
  yfr: "\uD835\uDD36",
  YIcy: "\u0407",
  yicy: "\u0457",
  Yopf: "\uD835\uDD50",
  yopf: "\uD835\uDD6A",
  Yscr: "\uD835\uDCB4",
  yscr: "\uD835\uDCCE",
  YUcy: "\u042E",
  yucy: "\u044E",
  Yuml: "\u0178",
  yuml: "\xFF",
  Zacute: "\u0179",
  zacute: "\u017A",
  Zcaron: "\u017D",
  zcaron: "\u017E",
  Zcy: "\u0417",
  zcy: "\u0437",
  Zdot: "\u017B",
  zdot: "\u017C",
  zeetrf: "\u2128",
  ZeroWidthSpace: "\u200B",
  Zeta: "\u0396",
  zeta: "\u03B6",
  Zfr: "\u2128",
  zfr: "\uD835\uDD37",
  ZHcy: "\u0416",
  zhcy: "\u0436",
  zigrarr: "\u21DD",
  Zopf: "\u2124",
  zopf: "\uD835\uDD6B",
  Zscr: "\uD835\uDCB5",
  zscr: "\uD835\uDCCF",
  zwj: "\u200D",
  zwnj: "\u200C"
});

/**
 * @deprecated use `HTML_ENTITIES` instead
 * @see HTML_ENTITIES
 */
exports.entityMap = exports.HTML_ENTITIES;
export default exports;